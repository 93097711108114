/* styles.css */
.fixed-bottom-left-right {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #e4d2cd;
  color: #f05f5f;
}
.tableContainer {
  /* 微软雅黑 */
  font-family: mircosoft yahei, sans-serif;
}

.spacer {
  height: 20px;
}


.App-link {
  color: #d9d5e2;
}

tr,td {
  border:  #b6b7be solid 1px  !important;
}
tr {
  margin-top: 5px;
  /* //圆弧边框 */
  border-radius: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
